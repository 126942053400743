<template>
	<div class="w-full h-full flex flex-col">
		<!-- <Header :classes="department && department.products && department.products.length && department.tasks && department.tasks.length ? 'items-end' : 'items-center'"> -->
		<Header classes="items-end">
			<div>
				<!-- <h1>Registrer</h1> -->
				<h1 v-if="department">{{ department.name }}</h1>
			</div>
			<!-- v-if="department && department.products && department.products.length && department.tasks && department.tasks.length" -->
			<Tabs 
				class="flex-none justify-center md:justify-start"
				:tabs="[
					{
						name: 'Produkter',
						alias: 'products',
					},
					{
						name: 'Oppgaver',
						alias: 'tasks',
					},
					// {
					// 	name: 'Kudos',
					// 	alias: 'kudos',
					// },
				]" 
				:active="entity" 
				@click="changeEntity( $event.alias )"
			/>
		</Header>
		
		<div 
			v-if="departmentId && departments && departments.length" 
			class="flex-grow overflow-hidden flex flex-col">
			
			<template v-if="entity == 'products' || entity == 'tasks'">
				<div class="flex-grow overflow-auto p-6 md:p-12">
					<TransactionCreate 
						:departmentId="departmentId" 
						@onTransactionChanged="transaction = $event"
						ref="TransactionCreateRef"
						:entity="entity"
						:key="'entity_'+entity"
					/>
				</div>
				<div class="flex-none px-6 py-6 flex space-x-4">
					<router-link 
						type="submit"
						:to="{ 
							name: 'portal-users', 
							params: { 
								accountId: accountId,
							}
						}"
						:disabled="loading"
						class="button w-full">
						
						Avbryt
					</router-link>
					
					<button 
						v-if="
							transaction && 
							( (transaction.products && transaction.products.length) || (transaction.tasks && transaction.tasks.length) )
						"
						type="submit"
						@click.prevent="onCreateTransaction()" 
						:disabled="loading == 'creating'"
						class="button submit w-full"
						:class="{'loading' : loading == 'creating'}">
						
						Registrer
					</button>
				</div>
			</template>
			<!-- <template v-else-if="entity == 'kudos'">
				<div class="flex-grow overflow-auto p-6 md:p-12">
					<TransactionsListForKudosGiving 
						:department="department" 
						:user="user"
						:users="users"
					/>
				</div>
			</template> -->
		</div>
		
		<MessageList 
			v-if="accountId"
			:inPortal="true"
			context="transactions"
		/>
	</div>
</template>

<script>
	export default {
		components: {
			'TransactionCreate': () => import('@/components/transactions/TransactionCreate.vue'),
			'MessageList': () => import('@/components/messages/MessageList.vue'),
			'TransactionsListForKudosGiving': () => import('@/components/transactions/TransactionsListForKudosGiving.vue'),
		},
		
		computed:{
			departments(){
				return this.$store.getters.getDepartments;
			},
			
			department(){
				return this.departments.find( d => d.departmentId == this.departmentId);
			},
			
			users(){
				return this.$store.getters.getUsers;
			},
			
			user(){
				if (!this.users) {
					return
				}
				return this.users.find( u => u.username == this.username);
			},
		},
		data(){
			return {
				departmentId: null,
				loading: null,
				transaction: null,
				username: null,
				resetTimerIntervalFunction: null,
				entity: 'products',
			}
		},
		
		methods: {
			// async onFetchDepartments(){
			// 	await this.$store.dispatch('listDepartments');
			// },
			
			onGoBack(){
				// this.$router.back();
				// // this.$router.back();
				// this.$router.push( { name: 'app-departments' } );
				this.$router.push( { name: 'portal-users' } );
			},
			
			async onCreateTransaction(){
				try {
					this.loading = 'creating';
					
					const user = {
						email: this.user.email,
						family_name: this.user.family_name,
						given_name: this.user.given_name,
						picture: this.user.picture,
					};
					
					this.transaction.profile = this.department.profile || null;
					
					try {
						if (this.user && this.user.profile) {
							this.transaction.profile = JSON.parse( this.user.profile );
						}
					} 
					catch (error) {
						console.error(error);
					}
					
					const response = await this.$axios.post( process.env.VUE_APP_API_ENDPOINT +'/portal/transaction', 
						{
							...this.transaction,
							username: this.username,
							user,
						},
						{
							headers: {
								authorization: this.account.accessToken,
							}
						}
					);
					
					const trackEmail = user && user.email ? user.email : this.username;
					const trackData = {
						...response.data,
						text: '*createTransaction* <https://upsellapp.io/admin/'+ this.account.accountId +'/home|'+this.account.name+'> by '+trackEmail +"\n" + JSON.stringify(response.data, null, 4),
					};
					analytics.track( 'createTransaction', trackData);
					
					// console.log('response', response.data);
					// this.$notify({ type: 'success', text: 'Salg er registrert' });
					
					this.transaction = null;
					this.$store.commit('setShowConfetti', true);
					
					// this.$router.push( { name: 'portal-users' } );
					
					this.$router.push({
						name: 'portal-departments', 
						params: { 
							accountId: this.accountId,
							username: this.username,
						}
					});
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						text: e.message || 'Kunne ikke registrere salg'
					});
			
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			changeEntity( entity ){
				this.entity = entity;
				this.transaction = null;
			},
		},
		
		async mounted(){
			if (this.$route.params.departmentId) {
				this.departmentId = this.$route.params.departmentId;
				this.username = this.$route.params.username;
				
				// await this.$store.dispatch('listCampaigns');
			}
			this.resetTimerIntervalFunction = setInterval( () => {
				this.$notify({ type: 'success', text: 'Gikk tilbake til start pga. inaktivitet' });
				this.$router.push( { name: 'portal-users' } );
			}, 1000 * 60 * 5);
			
			if ( this.department && this.department.products && this.department.products.length) {
				this.entity = 'products';
			}
			else if ( this.department && this.department.tasks && this.department.tasks.length) {
				this.entity = 'tasks';
			}
		},
		
		beforeDestroy() {
			if (this.resetTimerIntervalFunction) {
				clearInterval(this.resetTimerIntervalFunction);
			}
		},
	}
</script>