var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full flex flex-col"},[_c('Header',{attrs:{"classes":"items-end"}},[_c('div',[(_vm.department)?_c('h1',[_vm._v(_vm._s(_vm.department.name))]):_vm._e()]),_c('Tabs',{staticClass:"flex-none justify-center md:justify-start",attrs:{"tabs":[
				{
					name: 'Produkter',
					alias: 'products',
				},
				{
					name: 'Oppgaver',
					alias: 'tasks',
				},
				// {
				// 	name: 'Kudos',
				// 	alias: 'kudos',
				// },
			],"active":_vm.entity},on:{"click":function($event){return _vm.changeEntity( $event.alias )}}})],1),(_vm.departmentId && _vm.departments && _vm.departments.length)?_c('div',{staticClass:"flex-grow overflow-hidden flex flex-col"},[(_vm.entity == 'products' || _vm.entity == 'tasks')?[_c('div',{staticClass:"flex-grow overflow-auto p-6 md:p-12"},[_c('TransactionCreate',{key:'entity_'+_vm.entity,ref:"TransactionCreateRef",attrs:{"departmentId":_vm.departmentId,"entity":_vm.entity},on:{"onTransactionChanged":function($event){_vm.transaction = $event}}})],1),_c('div',{staticClass:"flex-none px-6 py-6 flex space-x-4"},[_c('router-link',{staticClass:"button w-full",attrs:{"type":"submit","to":{ 
						name: 'portal-users', 
						params: { 
							accountId: _vm.accountId,
						}
					},"disabled":_vm.loading}},[_vm._v(" Avbryt ")]),(
						_vm.transaction && 
						( (_vm.transaction.products && _vm.transaction.products.length) || (_vm.transaction.tasks && _vm.transaction.tasks.length) )
					)?_c('button',{staticClass:"button submit w-full",class:{'loading' : _vm.loading == 'creating'},attrs:{"type":"submit","disabled":_vm.loading == 'creating'},on:{"click":function($event){$event.preventDefault();return _vm.onCreateTransaction()}}},[_vm._v(" Registrer ")]):_vm._e()],1)]:_vm._e()],2):_vm._e(),(_vm.accountId)?_c('MessageList',{attrs:{"inPortal":true,"context":"transactions"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }